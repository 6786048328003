<template>
  <div>
    <sidebar v-if="notAuth == true" />
    <div class="page-container">
      <div class="dashboard-body">
        <dashHeader v-if="notAuth == true" />
        <router-view />
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import sidebar from "@/components/layout/sidebar.vue";
import dashHeader from "@/components/layout/dashHeader.vue";
import {messaging} from '@/firebase'
import {getToken , onMessage }  from "firebase/messaging"
import Toast from 'primevue/toast';

export default {
  data() {
    return {
      notAuth: true,
    };
  },
  components: {
    sidebar,
    dashHeader,
    Toast
  },
  created() {
    if (
      this.$route.fullPath.includes("login") ||
      this.$route.fullPath.includes("register") ||
      this.$route.fullPath.includes("completeRegister")
    ) {
      this.notAuth = false;
      console.log("tag", "rfrfrfrf");
    }
  },
  methods:{
      // making request permission to ask user to accept Notification  
      async requestPermission(){
        const permission = await Notification.requestPermission()
        if( permission === "granted" ){

          // console.log('granted')
          // Generate token
          // we get the token from project setting => cloud messaging => generateKey
          getToken( messaging , {PublicVapidKey:"BKDiTM0rDWmmMh9B0S5fUSK_hmRKR36xeEt4fHtuiSDqVpx0eQd_loMQke-ZnecoQsr-mWzPQW0GD8cjSgL3uhI"} )
          // {vapidKey:"BFpjV9Ma8fIm3fnaCxRZMuQM_iPkZcyUpmje05C7sG-S7K7MNcep0DLwwim9mKV0w6hyLKaPtyHQDiXlJBol64w"}
          .then((currentToken) => {

            if (currentToken) {
              localStorage.setItem('FCMToken', currentToken.toString());
              console.log(currentToken.toString())
            } else {
                  // Show permission request UI
                  console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });


          //To handle foreground messages
          onMessage(messaging, (message) => {
            this.$toast.add({ severity: 'success', summary: message.notification.body, life: 5000 });
            console.log('fcm is \n',message)           
          })

          // messaging.onMessage((message) => {
          //   this.$toast.add({ severity: 'success', summary: message.notification.body, life: 5000 });
          //   console.log('fcm is \n', message);
          // });



        }else if( permission === "denied" ){

            console.log('you denied')

        }
      },
    
  },
  mounted() {
    this.requestPermission();
   setTimeout(() => {
     document.querySelectorAll('.dataTables_empty').forEach((el) => {
      el.parentElement.remove();
      console.log(document.querySelectorAll('.dataTables_empty'))
    } )
   }, 1000);
  },
  updated() {
     setTimeout(() => {
     document.querySelectorAll('.dataTables_empty').forEach((el) => {
      el.parentElement.remove();
      console.log(document.querySelectorAll('.dataTables_empty'))
    } )
   }, 1000);
  }
};
</script>
<style lang="scss">
.p-message-text.p-message-text{
  font-family: "Cairo", sans-serif !important;
}
.p-message-wrapper{
  display: flex !important;
  justify-content: space-between !important;
  padding: 5px 10px !important;
}
</style>
